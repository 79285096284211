import React from "react"
import Layout from "../components/layout"
import MissionVision from "../components/who-we-are/mission-vision"
import { StaticImage } from "gatsby-plugin-image"
import SubHeader from "../components/sub-header"
import { graphql } from "gatsby"
import Image from "gatsby-image"

const text = (
  <>
    <span className="text-green-900">Philippine Earth Justice Center</span> is a
    non-stock and non-profit corporation which was established to provide legal
    assistance to victims of environmental injustice, conduct policy research on
    the environment, advocate policy reforms, assist in building local
    capacities for environmental protection and promote sustainability and
    protection of human rights.
  </>
)

export default function WhoWeAre({ data }) {
  const missionVission = {
    mission: {
      title: data.wpPage.mission_image.missionImage.title,
      image: (
        <Image
          fluid={
            data.wpPage.mission_image.missionImage.localFile?.childImageSharp
              ?.fluid
          }
          alt={data.wpPage.mission_image.missionImage.title}
          className="h-full w-full object-fit"
        />
      ),
      description: data.wpPage.mission_image.missionImage.description,
    },
    vision: {
      title: data.wpPage.vision_image.visionImage.title,
      direction: "flex-row-reverse",
      image: (
        <Image
          fluid={
            data.wpPage.vision_image.visionImage.localFile?.childImageSharp
              ?.fluid
          }
          alt={data.wpPage.vision_image.visionImage.title}
          className="object-fit filter hover:filter-brightness-105"
        />
      ),
      description: data.wpPage.vision_image.visionImage.description,
    },
  }

  return (
    <>
      <Layout>
        <div className="bg-white overflow-hidden">
          <SubHeader text={data.wpPage.content} />

          <div className="relative w-full">
            <MissionVision data={missionVission.vision} />
            <div className="p-5">&nbsp;</div>
            <MissionVision data={missionVission.mission} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query WhoWeAre {
    wpPage(id: { eq: "cG9zdDoyNjI=" }) {
      id
      title
      content
      vision_image {
        visionImage {
          title
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      mission_image {
        missionImage {
          title
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
